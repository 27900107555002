<template>
  <div class="banners-content">
    <a-row
      class="d-flex align-items-center mb-4"
      style="margin: -30px; padding: 8px 30px 22px 30px; background: #fff"
    >
      <a-col :span="10">
        <h3 class="mb-0" style="font-size: 20px">Banners & Stories</h3>
      </a-col>
      <a-col :span="14" class="text-right">
        <a-button
          v-if="permissionCreate"
          type="primary"
          size="large"
          style="font-size: 16px; font-weight: 300; border-radius: 2px"
          @click="createPromo"
        >
          <a-icon type="plus" /> Create new
        </a-button>
      </a-col>
    </a-row>
    <div style="padding: 24px; background-color: #fff">
      <div class="d-flex align-items-center mb-4">
        <ProductSelect v-model="filters.promoType" class="mr-3" @change="listUpdate"  :disabled="promoLoading" style="width: 220px;"/>
        <AppSelect v-model="filters.applicationId" :apps="apps" class="mr-3"  @change="listUpdate"
                   :disabled="appsLoading"
                   style="width: 220px;"/>
        <LocalizationsSelect v-model="filters.localizationId" :localizations="languagesOptions"
                             :disabled="languagesLoading"
                             class="mr-3" @change="listUpdate"/>
        <ActiveStatus v-model="filters.active"  @change="listUpdate"  :disabled="promoLoading"/>
        <a-input-search
            style="width: 200px; height: 40px; margin-top: auto;"
            size="large"
            v-model="filters.name"
            placeholder="Input search text"
            enter-button
            @search="listUpdate"
            :disabled="promoLoading"
            class="ml-auto"
          />
      </div>
      <draggable
        v-if="promoImages.length"
        v-model="promoImages"
        v-bind="dragOptions"
        @start="drag = true"
        @end="sortPromo()"
      >
        <transition-group>
          <PromoImageCard
            v-for="(promo, index) in promoImages"
            :class="{ 'mt-4': index }"
            :promo-info="promo"
            :permissionEdit="permissionEdit"
            :key="promo.promoId"
            @childOpenFunction="openPromoPage(promo.promoType, promo.promoId)"
          />
        </transition-group>
      </draggable>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AppSelect from '@/components/custom/filters/appSelect.vue'
import ProductSelect from '@/components/custom/filters/productSelect.vue'
import LocalizationsSelect from '@/components/custom/filters/localizationsSelect.vue'
import ActiveStatus from '@/components/custom/filters/activeStatus.vue'
import PromoImageCard from '@/views/apps/promo-images/promoList/promoImageCard.vue'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import draggable from 'vuedraggable'
export default {
  name: 'Promo-images',
  mixins: [accessMix],
  components: {
    draggable,
    ProductSelect,
    AppSelect,
    LocalizationsSelect,
    ActiveStatus,
    PromoImageCard,
  },
  props: {
    bannerInfo: {
      type: Object,
    },
    banId: {
      type: Number,
    },
    banObj: {
      type: Object,
    },
  },
  async created() {
    // this.$root.$on('bannersActiveChange', () => {
    //   this.getBanners()
    // })
    await this.fetchApplications()
    await this.fetchLocalizations()
    await this.listUpdate()
  },
  data() {
    return {
      promoLoading: false,
      appsLoading: false,
      languagesLoading: false,
      promoImages: [],
      filteredDisplays: [1, 3],
      languages: [],
      displays: [
        { label: 'Live', value: 1 },
        { label: 'Promo', value: 3 },
        { label: 'Main', value: 4 },
      ],
      drag: false,
      apps: undefined,
      filters: {
        applicationId: undefined,
        localizationId: undefined,
        promoType: undefined,
        active: true,
        name: undefined,
      },
      pagination: {
        current: 1,
        results: 15,
        total: 0,
      },
    }
  },
  computed: {
    ...mapState(['user']),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    languagesOptions() {
      return this.languages.map(function (row) {
        return { value: row.id, label: row.desc }
      })
    },

    permissionCreate() {
      return this.checkPermissions(this.user.permissions, 'banners', 'create') && this.checkPermissions(this.user.permissions, 'stories', 'create')
    },
    permissionEdit() {
      return this.checkPermissions(this.user.permissions, 'banners', 'edit')
    },
  },
  methods: {
    async listUpdate() {
      try {
        this.promoLoading = true
        await this.fetchPromo()
      } catch (e) {
        console.error(e)
      } finally {
        this.promoLoading = false
      }
    },
    openPromoPage(promoType, promoId) {
      this.$router.push(`/promo-images/${promoType}/${promoId}`)
    },
    async fetchPromo() {
      const { active, promoType, localizationId, applicationId, name } = this.filters
      const searchParams = new URLSearchParams()
      searchParams.append('active', active)

      if (promoType) {
        searchParams.append('promoType', promoType)
      }

      if (localizationId) {
        searchParams.append('lang_id', localizationId)
      }

      if (applicationId) {
        searchParams.append('app_id', applicationId)
      }
      if (name) {
        searchParams.append('name', name)
      }

      const response = await apiClient.get(`admin/promo?${searchParams}`)
      this.promoImages = response.data.data
    },
    createPromo() {
      this.$router.push('/promo-images/create-promo')
    },
    async fetchApplications() {
      this.appsLoading = true
      try {
        const url = '/admin/applications'
        const response = await apiClient.get(url)
        if (response.data.data) {
          this.apps = response.data.data
          this.apps.forEach(item => {
            if (item.is_default === 1) {
              this.filters.applicationId = item.application_id
            }
          })
        }
      } finally {
        this.appsLoading = false
      }
    },
    async fetchLocalizations() {
      this.languagesLoading = true
      const url = '/admin/localizations'
      try {
        const response = await apiClient.get(url)
        this.languages = response.data.data
      } catch (error) {
        console.log('Error while trying to get locales', error)
      } finally {
        this.languagesLoading = false
      }
    },
    async sortPromo() {
      const promoSort = this.promoImages.map((promo, index, promoArr) => {
        return {
          promoId: promo.promoId,
          promoType: promo.promoType,
          sort: promoArr.length - index,
        }
      })
      const sort = { sort: promoSort }
      try {
        await apiClient.post(
          `/admin/promo/sort/${this.filters.applicationId}`,
          sort,
        )
        this.$notification.success({
          message: 'Sucessfully sorted',
        })
      } catch (error) {
        console.log(error)
      }
      this.drag = false
    },
  },
}
</script>
<style>
/*.ant-modal { width: 1000px !important; }*/
.ant-modal-title {
  font-weight: bold !important;
  font-size: 18px !important;
}
.banners-active-filter-switch {
  /*border: 1px solid #000;*/
  display: inline-block !important;
  padding: 0 0.5rem 0 0.5rem;
  line-height: 38px;
  width: auto;
}
.banners-loading-spinner {
  /*width: 64px;*/
  /*height: 64px;*/
  border-radius: 2px;
  padding: 1rem;
  font-size: 96px;
  /*background: rgba(0, 0, 0, 0.1);*/
  margin-top: 6rem;
  position: absolute;
  margin-left: calc(50% - 40px);
  z-index: 999999;
}
.select-filter-localization {
  max-height: 38px !important;
  width: 100%;
}
.filter-drawer .ant-drawer-content-wrapper,
.filter-drawer .ant-drawer-content,
.filter-drawer .ant-drawer-wrapper-body {
  overflow: initial;
}
/* @media screen and (min-width: 1024px) {
      .filter-drawer .ant-drawer-wrapper-body {
        overflow-y: hidden;
      }
    } */
</style>
<style scoped>
.banner-slider::before {
  display: none;
}
.banner-slider {
  display: grid;
  grid-template-columns: repeat(auto-fit, 343px);
  grid-gap: 32px 57px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 24px 0;
  background: #fff;
  border-radius: 2px;
}
.banner-modal {
  width: 1000px !important;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 343px);
  grid-gap: 32px 57px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 24px 0;
  background: #fff;
  border-radius: 2px;
}
.flip-grid-move {
  transition: transform 0.5s;
  cursor: pointer;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

@media screen and (max-width: 1473px) {
  .banner-slider {
    grid-template-columns: repeat(3, 250px);
  }
  .banner-slider .flex-column {
    width: 100px !important;
  }
}
@media screen and (max-width: 1200px) {
  .banner-slider {
    grid-template-columns: repeat(auto-fit, 250px);
  }
}
</style>
