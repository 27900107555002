<template>
  <div class="banner-card">
    <div v-if="displayType === 'banner'" @click="openEditModal">
      <img class="banner-card__img" :src="promoInfo.imagePath" alt="" />
    </div>
    <div v-if="displayType === 'story'">
      <div v-if="isPreviewAvailable">
        <img @click="openEditModal" class="banner-card__story-img" :src="selectedPreview[0].path" alt="" />
        <a-tabs :default-active-key="languages[0].name" :active-key="selectedLanguage" @change="handleTabChange"
          :tabBarStyle="{ border: 'none' }">
          <a-tab-pane v-for="language in languages" :key="language.desc" :tab="language.name" />
        </a-tabs>
      </div>
    </div>
    <div @click="openEditModal" class="flex-grow-1">
      <div class="mb-3 d-flex align-items-center">
        <div class="util-width">
          <span class="banner-card__title">{{ displayType.charAt(0).toUpperCase() + displayType.slice(1) }} name</span>
          <div v-if="promoInfo.name">{{ promoInfo.name }}</div>
          <div v-else>null</div>
        </div>
        <div class="util-width">
          <span class="banner-card__title">Activity</span>
          <div>
            {{ formatDate(this.promoDateStart) }} -
            <span v-if="this.promoDateEnd">{{ formatDate(this.promoDateEnd) }}</span>
            <span v-else style="font-size: 20px;">&infin;</span>
          </div>
        </div>
        <div class="ml-auto">
          <a-switch class="ml-auto" :default-checked="this.switchActive" @change="promoActiveSwitch"
            :disabled="!permissionEdit" />
        </div>
      </div>
      <div class="mt-3 d-flex align-items-center">
        <div v-if="displayType === 'banner'" class="util-width">
          <span class="banner-card__title">Section</span>
          <div style="line-height: 1.8rem">
            <a-tag style="margin-right: 15px; padding: 1px 8px; border-radius: 2px"
              v-for="display in promoInfo.displays" :key="display.id">
              {{ displays[display.id] }}
            </a-tag>
          </div>
        </div>
        <div v-if="promoType" class="util-width">
          <span class="banner-card__title">Link type</span>
          <div>{{ promoType }}</div>
        </div>
        <div v-if="displayType === 'story'">
          <span class="banner-card__title">UUID</span>
          <div>{{ promoInfo.uuid }}</div>
        </div>
      </div>
      <div class="mt-3 d-flex align-items-center">
        <div class="util-width">
          <span class="banner-card__title">Apps</span>
          <div style="line-height: 2.1rem">
            <a-tag style="margin-right: 15px; padding: 1px 8px; border-radius: 2px"
              v-for="app in promoInfo.apps.slice(0, 3)" :key="app.id">
              {{ app.name }}
              <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="ml-1 text-success" />
              <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="ml-1 text-primary" />
              <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="ml-1 text-default" />
            </a-tag>
            <a-tag v-if="promoInfo.apps.length > 3">
              ...
            </a-tag>
          </div>
        </div>
        <div v-if="displayType === 'banner'" class="util-width">
          <span class="banner-card__title">Banner ID</span>
          <div>{{ promoInfo.id }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'

export default {
  name: 'promoImageCard',
  props: ['promoInfo', 'permissionEdit'],
  computed: {
    ...mapState(['user']),
    displayType() {
      return this.promoInfo.promoType
    },
    switchActive() {
      if (this.displayType === 'banner') {
        return this.promoInfo.isActive
      }
      return this.promoInfo.is_active
    },
    promoDateStart() {
      if (this.displayType === 'banner') {
        return this.promoInfo.startsAt
      }
      return this.promoInfo.start
    },
    promoDateEnd() {
      if (this.displayType === 'banner') {
        return this.promoInfo.endsIn
      }
      return this.promoInfo.end
    },
    selectedPreview() {
      if (this.displayType === 'banner') {
        return null
      }
      return this.promoInfo.preview[this.selectedLanguage]
    },
    bannerRedirect() {
      if (this.displayType === 'banner') {
        return this.promoInfo.redirect
      }
      return null
    },
    promoType() {
      if (this.displayType === 'story' && this.promoInfo.type !== null) {
        return this.promoInfo.type
      }
      if (this.displayType === 'banner' && this.bannerRedirect !== null) {
        return this.bannerRedirect.type.charAt(0).toUpperCase() + this.bannerRedirect.type.slice(1)
      }
      return null
    },
    isPreviewAvailable() {
      if (this.displayType === 'banner') {
        return null
      }
      return Array.isArray(this.promoInfo.preview) ? this.promoInfo.preview.length > 0 : Object.keys(this.promoInfo.preview).length > 0
    },
  },
  mounted() {
    // this.promoInfo.image_path = '/public/banners/dimmy.jpg'
  },
  data() {
    return {
      languages: [],
      selectedLanguage: '',
      displays: {
        1: 'Live',
        3: 'Promo',
        4: 'Main',
      },
      type: 'banner',
    }
  },
  created() {
    if (this.displayType === 'story') {
      const langKeys = Object.keys(this.promoInfo.preview)
      this.languages = langKeys.map((lang) => {
        return {
          name: lang.toUpperCase(),
          desc: lang,
        }
      })
      if (this.promoInfo.id === 26) {
        console.log(this.selectedPreview)
      }
      this.selectedLanguage = this.languages[0].desc
    }
  },
  methods: {
    openEditModal(event) {
      event.preventDefault()
      if (!event.target.classList.contains('ant-switch')) {
        this.$emit('childOpenFunction')
      }
    },
    handleTabChange(selectedLangKey) {
      this.selectedLanguage = selectedLangKey
    },
    banActiveSwitch(checked) {
      const url = `/admin/banners/${this.promoInfo.id}/status`
      apiClient
        .patch(url, {
          is_active: +checked,
        })
        .then((response) => {
          this.$notification.success({
            message: 'Active status changed',
            description: this.promoInfo.name,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async storyActiveSwitch(checked) {
      try {
        const storyPayload = {
          is_active: checked,
        }
        const updateResponse = await apiClient.patch(
          `/admin/stories/${this.promoInfo.id}`,
          storyPayload,
        )
        this.$notification.success({
          message: 'Active status changed',
          description: `${this.promoInfo.name} status have been changed`,
        })
        if (updateResponse.data.error) {
          this.$message.error(updateResponse.data.error.message)
          this.loading = false
          return
        }
      } catch (error) {
        console.log(error)
      }
    },
    promoActiveSwitch(checked) {
      if (this.displayType === 'banner') {
        this.banActiveSwitch(checked)
      } else {
        this.storyActiveSwitch(checked)
      }
    },
    formatDate: function (date) {
      return this.$moment(date).tz('Etc/GMT-3').format('DD.MM.YY HH:mm')
    },
  },
}
</script>

<style lang="scss" scoped>
.util-width {
  width: 40%;
}
.banner-card {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 10px 24px;
  display: flex;
  align-items: center;

  &__img {
    width: 352px;
    margin-right: 16px;
  }

  &__story-img {
    width: 200px;
    height: 200px;
    margin-right: 2rem;
  }

  &__title {
    color: #bfbfbf;
  }
}
</style>
